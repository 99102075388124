import React from 'react';
import { SiteMetadata } from '../components';
import { Layout } from '../layouts/Layout';

import 'typeface-roboto';
import '../styles/style.css';

export default () => {
  return (
    <Layout>
      <div className="my-24 py-20 px-20 items-center flex">
        <SiteMetadata
          title="Affiliate Disclaimer"
          description="Affiliate Disclaimer for The Shows Must Go Online"
        />
        <div className="container text-indigo-600">
          <h1 className="text-3xl lg:text-5xl font-extrabold leading-tight">
            Affiliate Disclaimer
        </h1>
          <p className="text-base mt-8 text-indigo-900">
            Some links on The Shows Must Go Online, which take you to external websites, are affiliate links.  This means that we may receive a commission if you subsequently make a purchase from that merchant.
            </p>
          <p className="text-base mt-8 text-indigo-900">
            The use of affiliate links helps fund the operational costs for The Shows Must Go Online. Because it is technically difficult for us to identify every individual affiliate link, you should assume that any link on this site may be an affiliate link even though only a fraction are.
          </p>
          <p className="text-base mt-8 text-indigo-900">
            It is our honest view that affiliate links have no impact on the editorial content of the site.  The long-term success of The Shows Must Go Online is based on attracting and keeping readers and this will only happen if the material we produce is seen as independent and trustworthy.
          </p>
          <p className="text-base mt-8 text-indigo-900">
            If we have affiliate links for particular companies covered in an article then we include them. If we don’t, we run the article anyway.
          </p>
          <p className="text-base mt-8 text-indigo-900">
            Our active affiliate partners are:  Amazon.co.uk, Gear4Music, Groupon, Craft Stash.
          </p>
          <p className="text-base mt-8 text-indigo-900">
            In many cases our affiliate arrangement only covers selected products or services.  Not every purchase made after clicking through to one of these companies will generate a commission.
        </p>
        </div>
      </div>
    </Layout>
  );
};
